<template>
  <input type="number" class="form-control" :class="{ 'is-invalid': hasError }" :id="inputId" :aria-describedby="helpTextId" :placeholder="placeholder" :value="value" @input="handleChange">
</template>

<script>
import inputMixin from './inputMixin'

export default {
  mixins: [
    inputMixin
  ],
  methods: {
    handleChange (data) {
      const value = typeof data === 'object' && data.target ? data.target.value : data
      this.$emit('value-change', Number(value))
    }
  }
}
</script>
